import { TranslationFile } from 'i18n'
import { IApplicationInformation } from 'interfaces/i-application-information'
import { useTranslation } from 'react-i18next'
import { checkIsMobile } from 'utils/commonhelpers'

export const ApplicationBankInfoForm = (data: IApplicationInformation) => {
	const { t } = useTranslation()

	return {
		fieldSetName: '',
		formName: 'ApplicationBankInfoForm',
		collapsable: false,
		show: true,
		visible: true,
		market: 'es',
		fullSize: true,
		col: 12,
		controls: [
			{
				required: true,
				controlName: 'ibanVal',
				type: 'text',
				label: t('forms:Iban'),
				description: t('forms:IbanDescription'),
				value: data.bankInfo.iban,
				validations: [
					{
						code: 'MustMatchPattern',
						value: '\\b^(ES)\\s*(\\d{2})\\s*(\\d{4})\\s*(\\d{4})\\s*(\\d{2})\\s*(\\d{10})\\b$',
						message: t('InvalidFormat'),
					},
				],
				visible: true,
				col: 12,
			},
		],
	}
}

export const ApplicationBankInfoFormFi = (data: IApplicationInformation) => {
	const { t } = useTranslation()

	return {
		fieldSetName: '',
		formName: 'ApplicationBankInfoFormFi',
		collapsable: false,
		show: true,
		visible: true,
		market: 'fi',
		fullSize: true,
		col: 12,
		controls: [
			{
				required: true,
				controlName: 'ibanVal',
				type: 'text',
				label: t('forms:Iban'),
				value: data.bankInfo.iban,
				maxLength: 18,
				validations: [
					{
						code: 'MustMatchPattern',
						value: '^(f|F)(i|I)([0-9]{16})$',
						message: t('InvalidIban'),
					},
				],
				visible: true,
				col: 12,
			},
		],
	}
}

export const ApplicationBankInfoFormDk = (data: IApplicationInformation) => {
	const { t } = useTranslation([TranslationFile.Forms])
	const isMobile = checkIsMobile()

	return {
		fieldSetName: '',
		formName: 'ApplicationBankInfoFormDk',
		collapsable: false,
		show: true,
		visible: true,
		market: 'dk',
		fullSize: true,
		col: 12,
		controls: [
			{
				required: false,
				controlName: 'directDebitClearingNumber',
				type: 'text',
				label: t('forms:DirectDebitClearingNumber'),
				value: data.bankInfo?.directDebitClearingNumber,
				visible: true,
				validations: [
					{
						code: 'MustMatchPattern',
						value: '^$|^(\\d{4}|)$', //
						message: t(
							'forms:ValidationMsgDirectDebitClearingNumber'
						),
					},
				],
				col: isMobile ? 12 : 4,
			},
			{
				required: false,
				controlName: 'directDebitAccount',
				type: 'text',
				label: t('forms:DirectDebitAccountNumber'),
				value: data.bankInfo?.directDebitAccount,
				validations: [
					{
						code: 'MustMatchPattern',
						value: '^$|^(\\d{7}|)$|^(\\d{8}|)$|^(\\d{9}|)$|^(\\d{10}|)$',
						message: t('forms:ValidationMsgDirectDebitAccount'),
					},
				],
				visible: true,
				col: isMobile ? 12 : 8,
			},
		],
	}
}
