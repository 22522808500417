const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'

export function validateIban(country: string, iban: string): boolean {
	if (!iban) return false

	let bic = null
	//Try generate BIC from IBAN
	switch (country) {
		case 'fi':
			bic = generateBicFromIbanFI(iban)
			break
		default:
			break
	}

	if (!bic) return false

	// Rearrange IBAN
	const modifiedIban = iban.substring(4) + iban.substring(0, 4)

	// Create an array to build the converted IBAN
	let sb: string[] = []

	// Iterate through each character in the modified IBAN
	for (const ch of modifiedIban) {
		const index = alphabet.indexOf(ch)

		// If character is not found in the alphabet, return false
		if (index === -1) {
			return false
		}

		// Append the index as string to the array
		sb.push(index.toString())
	}

	// Join the array into a single string
	let convertedIban = sb.join('')
	let remainder = 0

	// Process the converted IBAN in chunks of 7 digits
	while (convertedIban.length > 7) {
		remainder =
			parseInt(remainder.toString() + convertedIban.substring(0, 7), 10) %
			97
		convertedIban = convertedIban.substring(7)
	}

	// Handle the remaining part of the IBAN
	remainder = parseInt(remainder.toString() + convertedIban, 10) % 97

	// Return true if the remainder is 1, else return false
	return remainder === 1
}

function generateBicFromIbanFI(iban: string) {
	iban = iban.replace(' ', '')

	for (let i = 7; i > 4; i -= 1) {
		const key = iban.substring(4, i)
		const value = finlandBic[key]

		if (value) {
			return value
		}
	}
	return null
}

const finlandBic: { [key: string]: string } = {
	'1': 'Nordea Pankki || NDEAFIHH',
	'2': 'Nordea Pankki || NDEAFIHH',
	'31': 'Handelsbanken || HANDFIHH',
	'33': 'Skandinaviska Enskilda Banken || ESSEFIHX',
	'34': 'Danske Bank || DABAFIHX',
	'36': 'S-Pankki || SBANFIHH',
	'37': 'DNB Bank || DNBAFIHX',
	'38': 'Swedbank || SWEDFIHH',
	'39': 'S-Pankki || SBANFIHH',
	'400': 'Säästöpankki || ITELFIHH',
	'402': 'Säästöpankki || ITELFIHH',
	'403': 'Säästöpankki || ITELFIHH',
	'405': 'Aktia Pankki || HELSFIHH',
	'406': 'Säästöpankki || ITELFIHH',
	'407': 'Säästöpankki || ITELFIHH',
	'408': 'Säästöpankki || ITELFIHH',
	'41': 'Säästöpankki || ITELFIHH',
	'42': 'Säästöpankki || ITELFIHH',
	'43': 'Säästöpankki || ITELFIHH',
	'44': 'Säästöpankki || ITELFIHH',
	'45': 'Säästöpankki || ITELFIHH',
	'46': 'Säästöpankki || ITELFIHH',
	'47': 'POP Pankki || POPFFI22',
	'48': 'Säästöpankki || ITELFIHH',
	'490': 'Säästöpankki || ITELFIHH',
	'491': 'Säästöpankki || ITELFIHH',
	'492': 'Säästöpankki || ITELFIHH',
	'493': 'Säästöpankki || ITELFIHH',
	'495': 'Säästöpankki || ITELFIHH',
	'496': 'Säästöpankki || ITELFIHH',
	'497': 'Aktia Pankki || HELSFIHH',
	'5': 'Osuuspankki || OKOYFIHH',
	'6': 'Ålandsbanken || AABAFI22',
	'713': 'CityBank || CITIFIHX',
	'715': 'Säästöpankki || ITELFIHH',
	'717': 'Bigbank || BIGKFIH1',
	'799': 'Holvi || HOLVFIHH',
	'8': 'Danske Bank || DABAFIHH',
}
