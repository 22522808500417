import React, { useContext, useEffect, useState } from 'react'
import { FormBuilder, formKeySelector } from '@tf/formbuilder'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import {
	ApplicationDispatchContext,
	LOAN_APPLICATION_ACTIONS,
} from 'contexts/ApplicationContext'
import { IApplicationInformation } from 'interfaces/i-application-information'
import { getLanguageLocale, getMarket } from 'utils/countryHelpers'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { FormFactory } from 'formBuilder/formFactory'
import { CustomerForm } from 'constants/enums/formTypes'

import './UpdateBankInfo.scss'
import updateBankInfoServices from 'services/updateBankInfoServices'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'
import pnServices from 'services/pnServices'
import { MSP_ACTIONS, MspDispatchContext } from 'components/multistep/Multistep'
import { validateIban } from 'utils/ibanHelper'
import appConfig from 'app.config'

export const UpdateBankInfoFI = () => {
	const { t } = useTranslation()
	const { loanApplication, dispatchLoanApplication }: IApplicationContext =
		useContext(ApplicationDispatchContext)
	const { msp, dispatchMsp } = useContext(MspDispatchContext)
	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<Error | any>()
	const [isValidIban, setIsValidIban] = useState<boolean>(false)
	const applicationId = loanApplication.applicationData?.loanNumber!

	let formSchema = FormFactory(
		CustomerForm.ApplicationBankInfoForm,
		{
			formName: 'bankInfoForm',
			blockName: 'bankInfoForm',
			market: getMarket(),
			locale: getLanguageLocale(),
			getDataUrl: '',
			validationErrorList: false,
			requiredMessage: t('ValidateRequired'),
		},
		loanApplication.applicationData as IApplicationInformation
	)

	const formStateValues = useSelector(
		formKeySelector(formSchema.formMeta.formName, 'controlsState')
	)

	const updateIban = (value: string | undefined) => {
		dispatchLoanApplication({
			type: LOAN_APPLICATION_ACTIONS.SET_UPDATE_APPLICATIONDATA,
			payload: {
				...loanApplication,
				updateApplicationData: {
					...loanApplication.updateApplicationData,
					bankInfo: {
						...loanApplication.updateApplicationData?.bankInfo,
						iban: value?.replaceAll(' ', '').toUpperCase(),
					},
				},
			},
		})
	}

	useEffect(
		() => {
			if (
				!validateIban(appConfig.country, formStateValues?.ibanVal.value)
			) {
				setIsValidIban(false)
				return
			}

			setIsValidIban(true)
			updateIban(formStateValues?.ibanVal.value)
		},
		[formStateValues?.ibanVal.value] // eslint-disable-line react-hooks/exhaustive-deps
	)

	const updateBankInfo = async () => {
		setLoading(true)
		setError(null)
		try {
			await updateBankInfoServices
				.updateBankInfo({
					applicationId: applicationId,
					bankInfo: loanApplication.updateApplicationData?.bankInfo!,
				})
				.then(async (response) => {
					if (response === 200) {
						await pnServices
							.printPn(applicationId, t)
							.then(async (response) => {
								if (response === 200) {
									dispatchMsp({
										type: MSP_ACTIONS.SET_INDEX,
										payload: {
											index: msp.index + 1,
										},
									})
								}
							})
					}
				})
		} catch (error) {
			console.warn(error)
			setError(error)
			setLoading(false)
		}
	}

	return (
		<div className="update-bank-info finland">
			{loading ? (
				<div className="col-spinner">
					<CircleSpinner />
				</div>
			) : (
				<>
					{error ? (
						<ErrorBox message={t('ErrorMsg')} />
					) : (
						<>
							<div className="update-bank-info-section">
								<div className="update-bank-info-description">
									<p>{t('UpdateBankInfoDescription')}</p>
								</div>
								{formSchema ? (
									<FormBuilder
										formSchema={formSchema}
										apiError={undefined}
										showErrorsInForm={true}
										showSubmitButton={false}
									/>
								) : (
									<CircleSpinner />
								)}
							</div>
							<div className="update-bank-info-next-button">
								<button
									type="button"
									disabled={
										!formStateValues?.ibanVal.isValid ||
										!isValidIban
									}
									className="btn btn-primary"
									onClick={() => updateBankInfo()}>
									{t('Next')}
								</button>
							</div>
						</>
					)}
				</>
			)}
		</div>
	)
}
