import React, { useContext } from 'react'

import Multistep from 'components/multistep/Multistep'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { IApplicationInformation } from 'interfaces/i-application-information'
import { IMultistepComponent } from 'interfaces/i-multistep'
import {
	CheckIsLifeCycleStatus,
	getQueryValues,
	isPsd2StatusSigned,
} from 'utils/loanHelpers'
import MissingKyc from 'components/application-blocks/application-offer-blocks/MissingKyc/MissingKyc'
import { SignPsd2Fi } from 'components/application-blocks/application-offer-blocks/SignPsd2/SignPsd2Fi'
import { SignPnFi } from 'components/application-blocks/application-offer-blocks/SignPn/SignPnFi'
import { PpiFi } from 'components/application-blocks/application-offer-blocks/Ppi/PpiFi'
import { LifeCycleStatus } from 'constants/enums/lifeCycleStatus'
import { UpdateBankInfoFI } from 'components/application-blocks/application-offer-blocks/UpdateBankInfo/UpdateBankInfoFI'

const isPsd2StatusDeclined = () => getQueryValues().psd2status === 'skip'

const isPds2Updated = (applicationData: IApplicationInformation) => {
	return isPsd2StatusSigned(applicationData) || isPsd2StatusDeclined()
}

export const LoanApplicationStepsFi = () => {
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)

	const loanApplicationSteps: IMultistepComponent[] = [
		{
			id: 1,
			header: 'forms:ApplicationMissingKyc',
			body: <MissingKyc />,
			show:
				loanApplication.applicationData?.personalInfo?.missingKyc !==
					null &&
				CheckIsLifeCycleStatus(
					[
						LifeCycleStatus.ReceiveSigning,
						LifeCycleStatus.PrintPromissoryNote,
					],
					loanApplication.applicationData?.lifeCycleStatus!
				),
			stepCompleted: false,
		},
		{
			id: 2,
			header: 'PSD2',
			body: <SignPsd2Fi />,
			show:
				loanApplication.applicationData?.signingUrls?.psD2
					?.isMandatory! &&
				!isPds2Updated(loanApplication.applicationData!),
			stepCompleted: isPds2Updated(loanApplication.applicationData!),
		},
		{
			id: 3,
			header: 'UpdateBankInfoHeader',
			body: <UpdateBankInfoFI/>,
			show: !loanApplication.applicationData?.bankInfo?.iban,
			stepCompleted: false,
		},
		{
			id: 4,
			header: 'BeforeLoanDisbursement',
			body: (
				<React.Fragment>
					<SignPnFi />
					<PpiFi />
				</React.Fragment>
			),
			show: true,
			stepCompleted: false,
		},
	]

	return (
		<div className="extra-margin-bottom-fi">
			<Multistep
				multisteps={loanApplicationSteps}
				resetInitialState={false}
			/>
		</div>
	)
}
